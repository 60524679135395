import React, { useRef, useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Link from "next/link";
import Logo from "/public/site-assets/svg/logo.svg";
import styles from "./styles.module.scss";
import Specialisms from "../../.sourceflow/specialisms.json";
import DownCaret from "/public/site-assets/svg/down-caret.svg";
import GoogleTranslate from "../../components/GoogleTranslate";

export default function Header({
  employerNav = true,
  enableNav = true,
  slug_url,
}) {
  const router = useRouter();

  const all_specialisms = Specialisms.category_values
    ?.sort((a, b) => a.order.en - b.order.en)
    .map((post) => post)
    .filter((item) => item.specialism_tag.en === "");
  //Check if menu dots are clicked
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuToggle = () => {
    setMenuOpen(!isMenuOpen);
    setOpen(!open);
  };

  //Check if nav menu dropdown are clicked
  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const dropdownMenuToggle = () => {
    setDropdownMenuOpen(!isDropdownMenuOpen);
    setSpecialismsDropdownMenuOpen(false);
  };

  const [isSpecialismsDropdownMenuOpen, setSpecialismsDropdownMenuOpen] =
    useState(false);
  const specialismsdropdownMenuToggle = () => {
    setDropdownMenuOpen(false);
    setSpecialismsDropdownMenuOpen(!isSpecialismsDropdownMenuOpen);
  };

  const [isJobSearchDropdownMenuOpen, setJobSearchDropdownMenuOpen] =
    useState(false);
  const jobSearchdropdownMenuToggle = () => {
    setDropdownMenuOpen(false);
    setJobSearchDropdownMenuOpen(!isJobSearchDropdownMenuOpen);
  };

  // To check if user click outside of the dropdown menu
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // ref.current.classList.toggle("active", false);
          setDropdownMenuOpen(isDropdownMenuOpen);
          setSpecialismsDropdownMenuOpen(isSpecialismsDropdownMenuOpen);
          setJobSearchDropdownMenuOpen(isJobSearchDropdownMenuOpen);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // End

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isMenuOpen ? "hidden" : "auto";
    }
  }, [isMenuOpen]);

  // To control navigation that has dropdown
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <>
      <section className={`${styles.root} pt-2 pb-4`}>
        <Container>
          <div className="d-flex flex-nowrap justify-content-between justify-content-lg-between align-items-center">
            <div className="position-relative logoWrapper me-4 mt-3">
              <Link href="/">
                <a
                  className="text-decoration-none logo d-block"
                  title="gap Personnel"
                  aria-label="gap Personnel"
                >
                  <Logo />
                </a>
              </Link>
            </div>
            <div className="d-flex flex-column align-items-end">
              <GoogleTranslate />
              {enableNav ? (
                <>
                  {employerNav ? (
                    <>
                      <div
                        className={`d-block d-lg-none mobile-nav position-relative shadow rounded-3 ${
                          isMenuOpen ? "active" : ""
                        }`}
                        onClick={menuToggle}
                      >
                        <span className="line d-block position-relative"></span>
                        <span className="line d-block position-relative"></span>
                      </div>
                      <div
                        className={`d-block d-lg-none mobile-nav-wrapper ${
                          isMenuOpen ? "active" : ""
                        }`}
                      >
                        <Container>
                          <Accordion flush open={open} toggle={toggle}>
                            <Nav>
                              <NavItem>
                                <Link href="/">
                                  <a
                                    className="text-decoration-none"
                                    onClick={menuToggle}
                                  >
                                    Home
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/who-we-are/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname == "/who-we-are"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Who we are
                                  </a>
                                </Link>
                                <AccordionHeader
                                  targetId="0"
                                  className="d-inline-block"
                                >
                                  {" "}
                                </AccordionHeader>
                                <AccordionBody
                                  accordionId="0"
                                  className="w-100"
                                >
                                  <ul className="list-unstyled">
                                    <li>
                                      <Link href="/meet-the-team/">
                                        <a
                                          className="text-decoration-none"
                                          onClick={menuToggle}
                                        >
                                          Meet the team
                                        </a>
                                      </Link>
                                    </li>
                                  </ul>
                                </AccordionBody>
                              </NavItem>
                              <NavItem>
                                <Link href="/employers-specialisms/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname ==
                                      "/employers-specialisms"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Sectors
                                  </a>
                                </Link>
                                <AccordionHeader
                                  targetId="1"
                                  className="d-inline-block"
                                >
                                  {" "}
                                </AccordionHeader>
                                <AccordionBody
                                  accordionId="1"
                                  className="w-100"
                                >
                                  <ul className="list-unstyled">
                                    {all_specialisms.map((post, index) => (
                                      <li key={index}>
                                        <Link
                                          href={`/employers-specialisms/${post.url_slug}/`}
                                        >
                                          <a
                                            className="text-decoration-none"
                                            onClick={menuToggle}
                                          >
                                            {post?.title.en}
                                          </a>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </AccordionBody>
                              </NavItem>
                              <NavItem>
                                <Link href="/solutions/supply-solutions/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname ==
                                      "/solutions/supply-solutions"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Supply solutions
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/lets-get-started/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname == "/lets-get-started"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Let's get started
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/employers-news/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname == "/employers-news"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    News & insights
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/employers-get-in-touch/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname ==
                                      "/employers-get-in-touch"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Get in touch
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/recruit-staff/">
                                  <a
                                    className="primaryBtn tertiary-color text-white m-0"
                                    onClick={menuToggle}
                                  >
                                    Recruit staff
                                  </a>
                                </Link>
                              </NavItem>
                            </Nav>
                          </Accordion>
                        </Container>
                      </div>
                      <div className="d-none d-lg-inline-flex">
                        <Nav className="align-items-center justify-content-end">
                          <NavItem>
                            <Link href="/">
                              <a className="text-decoration-none">Home</a>
                            </Link>
                          </NavItem>
                          <div
                            className={`nav-item d-flex align-items-center position-relative w-children ${
                              isDropdownMenuOpen ? "active" : ""
                            }`}
                            ref={wrapperRef}
                          >
                            <Link href="/who-we-are/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/who-we-are"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Who we are
                              </a>
                            </Link>
                            <UncontrolledDropdown
                              direction="down"
                              onClick={dropdownMenuToggle}
                            >
                              <DropdownToggle>
                                <DownCaret />
                              </DropdownToggle>
                              <DropdownMenu className="border-0">
                                <DropdownItem
                                  className="p-0"
                                  aria-label="dropdown"
                                >
                                  <Link href="/meet-the-team/">
                                    <a
                                      className={`text-decoration-none d-block ${
                                        router.pathname == "/meet-the-team"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      Meet the team
                                    </a>
                                  </Link>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <div
                            className={`nav-item d-flex align-items-center position-relative w-children ${
                              isSpecialismsDropdownMenuOpen ? "active" : ""
                            }`}
                            ref={wrapperRef}
                          >
                            <Link href="/employers-specialisms/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/employers-specialisms"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Sectors
                              </a>
                            </Link>
                            <UncontrolledDropdown
                              direction="down"
                              onClick={specialismsdropdownMenuToggle}
                            >
                              <DropdownToggle>
                                <DownCaret />
                              </DropdownToggle>
                              <DropdownMenu className="border-0">
                                {all_specialisms.map((post, index) => (
                                  <DropdownItem
                                    className="p-0"
                                    key={index}
                                    aria-label={`${post.title.en}`}
                                  >
                                    <Link
                                      href={`/employers-specialisms/${post.url_slug}/`}
                                    >
                                      <a
                                        className={`d-block text-decoration-none ${
                                          post.url_slug == `${slug_url}`
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {post?.title.en}
                                      </a>
                                    </Link>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <NavItem>
                            <Link href="/solutions/supply-solutions/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname ==
                                  "/solutions/supply-solutions"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Supply solutions
                              </a>
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link href="/lets-get-started/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/lets-get-started"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Let's get started
                              </a>
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link href="/employers-news/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/employers-news"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                News & insights
                              </a>
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link href="/employers-get-in-touch/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/employers-get-in-touch"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={menuToggle}
                              >
                                Get in touch
                              </a>
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link href="/recruit-staff/">
                              <a className="primaryBtn tertiary-color text-white m-0">
                                Recruit staff
                              </a>
                            </Link>
                          </NavItem>
                        </Nav>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`d-block d-lg-none mobile-nav position-relative shadow rounded-3 ${
                          isMenuOpen ? "active" : ""
                        }`}
                        onClick={menuToggle}
                      >
                        <span className="line d-block position-relative"></span>
                        <span className="line d-block position-relative"></span>
                      </div>
                      <div
                        className={`d-block d-lg-none mobile-nav-wrapper ${
                          isMenuOpen ? "active" : ""
                        } ${employerNav ? "" : "jobseeker-nav"}`}
                      >
                        <Container>
                          <Accordion flush open={open} toggle={toggle}>
                            <Nav>
                              <NavItem>
                                <Link href="/">
                                  <a
                                    className="text-decoration-none"
                                    onClick={menuToggle}
                                  >
                                    Home
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/jobs/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname == "/jobs" ? "active" : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Jobs
                                  </a>
                                </Link>
                                <AccordionHeader
                                  targetId="0"
                                  className="d-inline-block"
                                >
                                  {" "}
                                </AccordionHeader>
                                <AccordionBody
                                  accordionId="0"
                                  className="w-100"
                                >
                                  <ul className="list-unstyled">
                                    <li>
                                      <Link href={`/job-search-by-client/`}>
                                        <a
                                          className={`text-decoration-none`}
                                          onClick={menuToggle}
                                        >
                                          Job Search by client
                                        </a>
                                      </Link>
                                    </li>
                                  </ul>
                                </AccordionBody>
                              </NavItem>
                              <NavItem>
                                <Link href="/job-seekers/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname == "/job-seekers"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Why work with us?
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/job-seekers-specialisms/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname ==
                                      "/job-seekers-specialisms"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Sectors
                                  </a>
                                </Link>
                                <AccordionHeader
                                  targetId="1"
                                  className="d-inline-block"
                                >
                                  {" "}
                                </AccordionHeader>
                                <AccordionBody
                                  accordionId="1"
                                  className="w-100"
                                >
                                  <ul className="list-unstyled">
                                    {all_specialisms.map((post, index) => (
                                      <li key={index}>
                                        <Link
                                          href={`/job-seekers-specialisms/${post.url_slug}/`}
                                        >
                                          <a
                                            className={`text-decoration-none ${
                                              post.url_slug == `${slug_url}`
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={menuToggle}
                                          >
                                            {post?.title.en}
                                          </a>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </AccordionBody>
                              </NavItem>
                              <NavItem>
                                <Link href="/job-seekers-news/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname == "/job-seekers-news"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    News & insights
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/gap-personnel-careers/">
                                  <a
                                    className={`text-decoration-none ${
                                      router.pathname ==
                                      "/gap-personnel-careers"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={menuToggle}
                                  >
                                    Opportunities
                                  </a>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link href="/job-seekers-get-in-touch/">
                                  <a
                                    className="primaryBtn secondary-color text-white m-0"
                                    onClick={menuToggle}
                                  >
                                    Get in touch
                                  </a>
                                </Link>
                              </NavItem>
                            </Nav>
                          </Accordion>
                        </Container>
                      </div>
                      <div
                        className={`d-none d-lg-inline-flex ${
                          employerNav ? "" : "jobseeker-nav"
                        }`}
                      >
                        <Nav className="align-items-center justify-content-end">
                          <NavItem>
                            <Link href="/">
                              <a className="text-decoration-none">Home</a>
                            </Link>
                          </NavItem>
                          {/* <NavItem>
                                                        <Link href="/jobs/">
                                                            <a className={`text-decoration-none ${router.pathname == "/jobs" ? "active" : ""}`}>
                                                                Jobs
                                                            </a>
                                                        </Link>
                                                    </NavItem> */}
                          <div
                            className={`nav-item d-flex align-items-center position-relative w-children ${
                              isJobSearchDropdownMenuOpen ? "active" : ""
                            }`}
                            ref={wrapperRef}
                          >
                            <Link href="/jobs/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/job-search-by-client"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Jobs
                              </a>
                            </Link>
                            <UncontrolledDropdown
                              direction="down"
                              onClick={jobSearchdropdownMenuToggle}
                            >
                              <DropdownToggle>
                                <DownCaret />
                              </DropdownToggle>
                              <DropdownMenu className="border-0">
                                <DropdownItem className="p-0">
                                  <Link href={`/job-search-by-client`}>
                                    <a
                                      className={`d-block text-decoration-none`}
                                    >
                                      Job search by client
                                    </a>
                                  </Link>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <NavItem>
                            <Link href="/job-seekers/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/job-seekers"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Why work with us?
                              </a>
                            </Link>
                          </NavItem>
                          <div
                            className={`nav-item d-flex align-items-center position-relative w-children ${
                              isSpecialismsDropdownMenuOpen ? "active" : ""
                            }`}
                            ref={wrapperRef}
                          >
                            <Link href="/job-seekers-specialisms/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/job-seekers-specialisms"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Sectors
                              </a>
                            </Link>
                            <UncontrolledDropdown
                              direction="down"
                              onClick={specialismsdropdownMenuToggle}
                            >
                              <DropdownToggle>
                                <DownCaret />
                              </DropdownToggle>
                              <DropdownMenu className="border-0">
                                {all_specialisms.map((post, index) => (
                                  <DropdownItem className="p-0" key={index}>
                                    <Link
                                      href={`/job-seekers-specialisms/${post.url_slug}/`}
                                    >
                                      <a
                                        className={`d-block text-decoration-none ${
                                          post.url_slug == `${slug_url}`
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {post?.title.en}
                                      </a>
                                    </Link>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <NavItem>
                            <Link href="/job-seekers-news/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/job-seekers-news"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={menuToggle}
                              >
                                News & insights
                              </a>
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link href="/gap-personnel-careers/">
                              <a
                                className={`text-decoration-none ${
                                  router.pathname == "/gap-personnel-careers"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Opportunities
                              </a>
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link href="/job-seekers-get-in-touch/">
                              <a className="primaryBtn secondary-color text-white m-0">
                                Get in touch
                              </a>
                            </Link>
                          </NavItem>
                        </Nav>
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
