
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";
import { NextSeo } from 'next-seo';

export default function SeoHead({
    site_name = "Gap Personnel",
    page_name = "Gap Personnel - Temporary and Permanent Recruitment",
    description = "Search and apply for your next job today, immediate starts available! Contact your local gap personnel branch.",
    canonical_link,
    og_image = "https://www.gap-personnel.com/site-assets/images/og-image.png",
    og_img_alt = "Gap Personnel - Temporary and Permanent Recruitment"
}) {
    return (
        <>
            <SourceFlowHead metaObject={metaObject}>
                <title>{page_name}</title>
                <meta name="title" content={page_name} />
                <meta name="description" content={description} />
                <link rel="canonical" href={`${canonical_link ? `https://www.gap-personnel.com${canonical_link}` : "https://www.gap-personnel.com/"}`} key="canonical" />
                <meta name="twitter:image" content="https://www.gap-personnel.com/site-assets/images/og-image.png" />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={page_name} />
            </SourceFlowHead>

            <NextSeo
                openGraph={{
                    url: `${canonical_link ? `https://www.gap-personnel.com${canonical_link}` : "https://www.gap-personnel.com/"}`,
                    title: page_name,
                    site_name: site_name,
                    description: description,
                    locale: 'en_GB',
                    images: [
                        {
                            url: og_image,
                            width: 1200,
                            height: 600,
                            alt: og_img_alt,
                            type: 'image/jpg',
                        }
                    ],
                }}
                twitter={{
                    handle: '@gappersonnel',
                    site: '@gappersonnel',
                    cardType: 'summary_large_image',
                }}
            />
        </>
    )
}